<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height of the product (mm)
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width of the product (mm)
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth (cm)
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="bigbox" id="feature-0" @change="bigboxChanged" />
                <label for="feature-0">BigBox</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="variozone" id="feature-1" @change="variozoneChanged" />
                <label for="feature-1">VarioZone</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#frost-filter" role="button" aria-expanded="true"
          aria-controls="frost-filter">
          Frost Free Options
        </h6>
        <div class="wrapper collapse show" id="frost-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="noFrost" id="frost-0" @change="noFrostChanged" />
                <label for="frost-0">NoFrost</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="frostFree" id="frost-1" @change="frostFreeChanged" />
                <label for="frost-1">Frost free system</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#colour-filter" role="button" aria-expanded="true"
          aria-controls="colour-filter">
          Colour
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#hinge-filter" role="button" aria-expanded="true"
          aria-controls="hinge-filter">
          Door hinge
        </h6>
        <div class="wrapper collapse show" id="hinge-filter">
          <VueMultiselect v-model="doorHinge" :options="doorHinges" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select door hinges" :searchable="false" selectLabel="" deselectLabel=""
            @input="doorHingeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#accessory-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Included accessories
        </h6>
        <div class="wrapper collapse show" id="accessory-filter">
          <VueMultiselect v-model="accessory" :options="accessories" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select accessories" :searchable="false" selectLabel="" deselectLabel=""
            @input="accessoryChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#type-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Product Type
        </h6>
        <div class="wrapper collapse show" id="type-filter">
          <VueMultiselect v-model="type" :options="types" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select types" :searchable="false" selectLabel="" deselectLabel=""
            @input="typeChanged" />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#control-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <VueMultiselect v-model="controlType" :options="controlTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="controlTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#noise-filter" role="button" aria-expanded="true"
          aria-controls="noise-filter">
          Noise
        </h6>
        <div class="wrapper collapse show" id="noise-filter">
          <VueMultiselect v-model="noise" :options="noiseLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select noises" :searchable="false" selectLabel="" deselectLabel=""
            @input="noiseChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#efficiency-filter" role="button" aria-expanded="true"
          aria-controls="efficiency-filter">
          Energy Efficiency
        </h6>
        <div class="wrapper collapse show" id="efficiency-filter">
          <VueMultiselect v-model="energyEfficiency" :options="energyEfficiencies" :multiple="true"
            :close-on-select="true" open-direction="bottom" placeholder="Select energy efficiencies" :searchable="false"
            selectLabel="" deselectLabel="" @input="energyEfficiencyChanged" />
        </div>
      </div>
    </div>
    <div class="filter-options dropdown">
      <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
        aria-controls="installation-filter">
        Installation Type
      </h6>
      <div class="wrapper collapse show" id="installation-filter">
        <VueMultiselect v-model="installation" :options="installations" :multiple="true" :close-on-select="true"
          open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
          deselectLabel="" @input="installationChanged" />
      </div>
    </div>
    <div class="filter-options dropdown">
      <h6 class="heading" data-toggle="collapse" data-target="#category-filter" role="button" aria-expanded="true"
        aria-controls="category-filter">
        Product category
      </h6>
      <div class="wrapper collapse show" id="category-filter">
        <VueMultiselect v-model="category" :options="categories" :multiple="true" :close-on-select="true"
          open-direction="bottom" placeholder="Select categories" :searchable="false" selectLabel="" deselectLabel=""
          @input="categoryChanged" />
      </div>
    </div>

    <div class="filter-options dropdown">
      <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
        aria-controls="series-filter">
        Product series
      </h6>
      <div class="wrapper collapse show" id="series-filter">
        <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
          open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
          @input="seriesChanged" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "FreestandingFreezerFilters",
  data: function () {
    return {
      heights: [],
      colors: [],
      widths: [],
      depths: [],
      serieses: [],
      connectivities: [],
      doorHinges: [],
      controlTypes: [],
      accessories: [],
      types: [],
      noiseLevels: [],
      energyEfficiencies: [],
      installations: [],
      categories: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      color: [],
      height: [],
      width: [],
      depth: [],
      series: [],
      connectivity: [],
      doorHinge: [],
      controlType: [],
      accessory: [],
      type: [],
      noise: [],
      energyEfficiency: [],
      installation: [],
      category: [],
      bigbox: false,
      variozone: false,
      noFrost: false,
      frostFree: false,
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_color_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_height_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_door_hinge",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.doorHinges = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_accessories_incl",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.accessories = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_energy_class_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyEfficiencies = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.installations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_product_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.types = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_category",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.categories = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },

    colorChanged() {
      let filters = {
        attr_color_marketing__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },


    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height_marketing__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    doorHingeChanged() {
      let filters = {
        attr_door_hinge__in: _.map(this.doorHinge, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    accessoryChanged() {
      let filters = {
        attr_accessories_incl__in: _.map(this.accessory, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    energyEfficiencyChanged() {
      let filters = {
        attr_energy_class_2010__in: _.map(this.energyEfficiency, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    noiseChanged() {
      let filters = {
        attr_noise_marketing__in: _.map(this.noise, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    typeChanged() {
      let filters = {
        attr_product_type__in: _.map(this.type, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    categoryChanged() {
      let filters = {
        attr_category__in: _.map(this.category, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },


    bigboxChanged() {
      let filters = {
        attr_bigbox: this.bigbox ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    variozoneChanged() {
      let filters = {
        attr_variozone: this.variozone ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    noFrostChanged() {
      let filters = {
        attr_nofrost: this.noFrost ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    frostFreeChanged() {
      let filters = {
        attr_frost_free: this.frostFree ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    existingFilters() {
      this.bigbox = this.selectedFilters.attr_bigbox;
      this.variozone = this.selectedFilters.attr_variozone;
      this.noFrost = this.selectedFilters.attr_nofrost;
      this.frostFree = this.selectedFilters.attr_frost_free;

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', '')
          sortedWidths.push(width)
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_height_marketing__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height_marketing__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', '')
          sortedHeights.push(height)
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', '')
          sortedDepths.push(depth)
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_color_marketing__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_color_marketing__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', '')
          sortedColors.push(color)
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', '')
          sortedSeries.push(series)
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }

      if (this.selectedFilters.attr_door_hinge__in) {
        let sortedHinges = [];
        let unsortedHinges = this.selectedFilters.attr_door_hinge__in.split('","');
        unsortedHinges.map((hinge) => {
          hinge = hinge.replaceAll('"', "");
          sortedHinges.push(hinge);
        });
        this.doorHinge = sortedHinges;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls = this.selectedFilters.attr_control_setting__in.split(
          '","'
        );
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }

      if (this.selectedFilters.attr_accessories_incl__in) {
        let sortedAccessories = [];
        let unsortedAccessories = this.selectedFilters.attr_accessories_incl__in.split(
          '","'
        );
        unsortedAccessories.map((accessory) => {
          accessory = accessory.replaceAll('"', "");
          sortedAccessories.push(accessory);
        });
        this.accessory = sortedAccessories;
      }
      if (this.selectedFilters.attr_energy_class_2010__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2010__in.split(
          '","'
        );
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', "");
          sortedEnergies.push(energy);
        });
        this.energyEfficiency = sortedEnergies;
      }
      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }
      if (this.selectedFilters.attr_noise_marketing__in) {
        let sortedNoises = [];
        let unsortedNoises = this.selectedFilters.attr_noise_marketing__in.split(
          '","'
        );
        unsortedNoises.map((noise) => {
          noise = noise.replaceAll('"', "");
          sortedNoises.push(noise);
        });
        this.noise = sortedNoises;
      }
      if (this.selectedFilters.attr_product_type__in) {
        let sortedTypes = [];
        let unsortedTypes = this.selectedFilters.attr_product_type__in.split('","');
        unsortedTypes.map((type) => {
          type = type.replaceAll('"', '')
          sortedTypes.push(type)
        });
        this.type = sortedTypes;
      }
      if (this.selectedFilters.attr_category__in) {
        let sortedCategories = [];
        let unsortedCategories = this.selectedFilters.attr_category__in.split('","');
        unsortedCategories.map((category) => {
          category = category.replaceAll('"', '')
          sortedCategories.push(category)
        });
        this.category = sortedCategories;
      }
    }
  },
};
</script>

<style scoped></style>